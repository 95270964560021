import { apiFetch } from '@/utils/api';
import type { iBook } from '@/types/apiModels';
import { eAPI_METHOD } from '@/types/enums';

// Get catalog books
export const apiGetCatalogBooks = async () => {
  const res = await apiFetch<Array<iBook>>('books', {
    method: eAPI_METHOD.GET,
    query: {
      is_coming_soon: false,
    },
  });

  return res || [];
};

// Get coming soon books
export const apiGetComingSoonBooks = async () => {
  const res = await apiFetch<Array<iBook>>('books', {
    method: eAPI_METHOD.GET,
    query: {
      is_coming_soon: true,
    },
  });

  return res || [];
};

// Get only user's books
export const apiGetMyBooks = async () => {
  const res = await apiFetch<Array<iBook>>('books/my/', {
    method: eAPI_METHOD.GET,
    query: {
      is_purchased_or_started: true, // will return only books that purchased or got by subscription but with reading progress
    },
  });

  return res || [];
};

// Add book to user's list
export const apiAddBookToMyBooks = async (slug: string) => {
  const body = new FormData();
  body.append('slug', slug);

  await apiFetch(`books/${slug}/add_to_user`, {
    method: eAPI_METHOD.POST,
    body,
  });
};

// Get book details
export const apiGetBookDetails = async (slug: string) => {
  const res = await apiFetch<iBook>(`books/${slug}`, {
    method: eAPI_METHOD.GET,
  });

  return res;
};

// Save current user reading progress
let apiTrackBookProgressController: AbortController;
export const apiTrackBookProgress = async (
  slug?: string,
  body?: Partial<iBook>
) => {
  apiTrackBookProgressController?.abort?.();
  apiTrackBookProgressController = new AbortController();

  await apiFetch(`books/${slug}/track`, {
    method: eAPI_METHOD.PUT,
    signal: apiTrackBookProgressController.signal,
    body,
  });
};

// Update book details
export const apiUpdateBookDetails = async (
  slug: string,
  body: Partial<iBook>
) => {
  const res = await apiFetch<iBook>(`books/my/${slug}`, {
    method: eAPI_METHOD.PATCH,
    body,
    customOptions: {
      handleErrorMessage: {
        showErrorMessage: true,
      },
    },
  });

  return res;
};
